import React from 'react'
import { Icon } from '@iconify/react';
import linkedin from "../../assets/icons/linkedin.svg"
import twitter from "../../assets/icons/twitter.svg"
import tiktok from "../../assets/icons/tiktok.svg"
import instagram from "../../assets/icons/instagram.svg"
import facebook from "../../assets/icons/facebook.svg"
import nextstay from "../../assets/icons/nextstaylogo1.png"

const Footer = () => {
    return (
        <div>
            <section className="footer">
                <div className="footer-wrap">
                    <div className="left">
                        <div className="footlogo">
            
                        <img  
                            className="nextstay-logo"
                            src={nextstay} alt="" /></div>
                        <p> Your gateway to the future of real estate. Combining blockchain, AI, and metaverse technologies, we empower users with secure transactions, virtual tours, and vibrant metaverse communities. Join us for property experiences like never before!
</p>
                        <ul className="contact">
                        <a href='https://www.facebook.com/profile.php?id=100094895430476' target="_blank"><li> 
                               <img src={facebook} alt="..." /> </li></a>
                                <a  href='https://www.linkedin.com/company/nextstay_io/' target="_blank"><li>   <img src={linkedin} alt="" /></li></a>
                                <a href='https://www.instagram.com/nextstay_io/' target="_blank"><li>   <img src={instagram} alt="" /></li></a>
                                <a href='https://twitter.com/nextstay_io' target="_blank"><li>   <img src={twitter} alt="" /></li></a>
                                <a href='https://www.tiktok.com/@nextstay_io' target="_blank"><li>   <img src={tiktok} alt="" /></li></a>
                           
                        </ul>
                    </div>
                    <div className="right">
                        <ul className="about">
                            <li>About</li>
                            <li>About us</li>
                            <li>Blog</li>
                            <li>Careers</li>
                            <li>Jobs</li>
                            <li>In Press</li>
                        </ul>
                        <ul className="support">
                            <li>Support</li>
                            <li>Contact us</li>
                            <li>Online Chat</li>
                            <li>Whatsapp</li>
                            <li>Telegram</li>
                            <li>Ticketing</li>
                        </ul>
                        <ul className="faq">
                            
                            <li>FAQ</li>
                            <li>Account</li>
                            <li>Manage Deliveries</li>
                            <li>Orders</li>
                            <li>Payments</li>
                            <li>Returns</li>
                        </ul>
                    </div>
                </div>
                <p>© 2000-2023, All Rights Reserved</p>
            </section>
        </div>
    )
}

export default Footer
