import { useEffect } from "react";
import Banner from "./banner/banner";
import Navbar from "./navbar/navbar";
import Community from "./page/Community";
import FQA, { Newsletter } from "./page/FQA";
import Footer from "./page/Footer";
import Marketplace from "./page/Marketplace";
import Reward from "./page/Reward";
import Vision from "./page/Vision";
import "./style/_Home.scss"

function Home() {



  return (
    <div className="main-home">
      <Banner />
      <Navbar />
      <Vision />
      <Marketplace />
      <Community />
      <Reward />
      <FQA />
      <Newsletter />
      <Footer />
    </div>
  );
}

export default Home;
