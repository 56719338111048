import React from "react";
import Cards from "../cards/Cards";
import pic1 from "../../assets/images/pic1.webp"
import pic2 from "../../assets/images/pic2.webp"
import pic3 from "../../assets/images/pic3.webp"
import pic4 from "../../assets/images/pic4.webp"
import meta1 from "../../assets/images/meta2.webp"
import meta2 from "../../assets/images/meta1.webp"
const Marketplace = () => {
  return (
    <div>
      <section className="marketplace">
        <div
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-once="false"
          className="heading-market-wrap"
        >
          <h1>Marketplace</h1>
          <h3>Welcome to the Meta Real Estate Hub
</h3>
        </div>
        <div className="trending-plots">
          <h3
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-once="false"
          >
            Trending Plots{" "}
          </h3>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="false"
            className="cards"
          >
            <Cards cardImg={pic1} />
            <Cards cardImg={pic2}/>
            <Cards cardImg={pic3} />
            <Cards cardImg={pic4}/>
          </div>
        </div>
        {/* <p>GO TO MARKETPLACE THROUGH APP</p> */}
        <div className="browse-land">
          <div
            data-aos="fade-zoom-out"
            data-aos-duration="1000"
            data-aos-once="false"
            className="left" >
            <div className="img-wrap">
              <img src={meta1}/>
            </div>
            <div className="details">
              <p className="pb-4">Browse Land Available For Sale</p>
              {/* <button className="btn-1">Download App</button> */}
            </div>
          </div>
          <div
            data-aos="fade-zoom-out"
            data-aos-duration="1000"
            data-aos-once="false"
            className="right"
          >
            <div className="img-wrap">
              <img   src={meta2} alt="" />
            </div>
            <div className="details">
              <p className="text-center">Make your ideas a reality <br></br> in the Metaverse. </p>
            </div>
          </div>
        </div>
        <div className="vector">
          <img src="\images/Vector.png" alt="" />
        </div>
      </section>
    </div>
  );
};

export default Marketplace;
