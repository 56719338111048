import React from 'react'
import { Icon } from '@iconify/react';
import "./Cards.scss"


const Cards = ({cardImg}) => {
    return (
        
        <div className='card-wrap'>
            <div className="top">
                <div className="top-box">
                    <img src={cardImg} alt="" />
                </div>
                <div className="top-details">

                    <p>Price</p>
                    <div className="price-det">
                        <p>USDT 1.1245</p>
                        <p>10 minutes ago</p>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="left-details">
                    <p>XYZ Plot</p>
                    <p>gold smith</p>
                </div>
                <div className="right-details">
                    <p>Land</p>
                    <div className="rating-wrap">
                        <Icon icon="bxs:map" color="#4edc37" />
                        <p>28,200</p>
                    </div>
                </div>
            </div>
            </div>
    
    
    )
}


export default Cards
