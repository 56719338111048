import React from "react";

const Community = () => {
  return (
    <div className="community">
      <div
        data-aos="fade-up"
        data-aos-duration="2000"
        data-aos-once="false"
        className="bg-community"
      >
        <div className="shad-1"></div>
        <div className="shad-2"></div>
      </div>
      <div className="community-wrap">
        <p>
        Step into a vibrant metaverse, become part of a thriving community of Innovators  & shape your own dynamic meta world!{" "}
        </p>
      </div>
    </div>
  );
};

export default Community;
