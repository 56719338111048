import React from 'react'
import { Icon } from '@iconify/react';
import line from "../../assets/images/Line 5.png"

const FQA = () => {
    return (
        <div className='main-fqa'>
            <section className='fqa'>
                <h1 data-aos="fade-down" data-aos-duration="1500" data-aos-once="false">FAQs</h1>
                {/* <div className="que-wrap">
                    <div className="que que1">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ?</p>
                        <Icon icon="ic:baseline-plus" color="white" width="38" />
                    </div>
                    <div className="que que2">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ?</p>
                        <Icon icon="ic:baseline-plus" color="white" width="38" />
                    </div>
                    <div className="que que3">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ?</p>
                        <Icon icon="ic:baseline-plus" color="white" width="38" />
                    </div>
                    <div className="que que4">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ?</p>
                        <Icon icon="ic:baseline-plus" color="white" width="38" />
                    </div>
                    <div className="que que5">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit ?</p>
                        <Icon icon="ic:baseline-plus" color="white" width="38" />
                    </div>
                </div> */}



                <div class="main-que accordion accordion-flush" id="accordionFlushExample">
                    <div data-aos="fade-right" data-aos-duration="1500" data-aos-once="false" class="que-wrap accordion-item">
                        <h2 class="que-heading accordion-header">
                            <button class="que-btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                <p>How does Next Stay use blockchain technology?
</p>
                                <span><Icon icon="ic:baseline-plus" color="white" width="38" /></span>
                                
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p>Next Stay leverages blockchain for transparent and secure property transactions, ensuring tamper-proof records of ownership, contracts, and transactions.
</p>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" data-aos-duration="1500" data-aos-once="false" class="que-wrap accordion-item">
                        <h2 class="que-heading accordion-header">
                            <button class="que-btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                <p>How can I buy and sell properties on Next Stay?
</p>
                                <span><Icon icon="ic:baseline-plus" color="white" width="38" /></span>
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p>Users can browse property listings, schedule virtual property tours, and engage in smart contract transactions to buy and sell properties.
</p>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-right" data-aos-duration="1500" data-aos-once="false" class="que-wrap  accordion-item">
                        <h2 class="que-heading accordion-header">
                            <button class="que-btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                <p>What is the AI-powered virtual assistant's role?
</p>
                                <span><Icon icon="ic:baseline-plus" color="white" width="38" /></span>
                            </button>
                        </h2>
                        <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p>Next Stay's AI chatbot offers personalized property recommendations, real-time guidance, and assistance in property exploration and transactions.
</p>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" data-aos-duration="1500" data-aos-once="false" class="que-wrap  accordion-item">
                        <h2 class="que-heading accordion-header">
                            <button class="que-btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                                <p>What is the role of NFTs on the platform?

</p>
                                <span><Icon icon="ic:baseline-plus" color="white" width="38" /></span>
                            </button>
                        </h2>
                        <div id="flush-collapsefour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p>Next Stay tokenizes real estate properties as NFTs, enabling users to buy, sell, and trade fractional ownership of properties.
</p>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-right" data-aos-duration="1500" data-aos-once="false" class="que-wrap  accordion-item">
                        <h2 class="que-heading accordion-header">
                            <button class="que-btn accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                <p>Are my transactions and data secure on Next Stay?
</p>
                                <span><Icon icon="ic:baseline-plus" color="white" width="38" /></span>
                            </button>
                        </h2>
                        <div id="flush-collapsefive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <p>Yes, Next Stay ensures the security of transactions and user data through blockchain technology and robust data protection measures.
</p>
                            </div>
                        </div>
                    </div>
                </div>








                <div className="btn-wrap">
                    <button className="btn">See more</button>
                </div>
            </section>
            <div className="vector">
                <img src="\images/Vector.png" alt="" />
            </div>
        </div>
    )
}

function Newsletter() {
    return (
        <div className='newsletter'>
            <h1>Newsletter</h1>
            <p>Be the first one to know  about discounts, offers and events weekly in your mailbox. Unsubscribe whenever you like with one click.</p>
            <div className="email-wrap">
                <div className="icon-wrap">
                    <Icon icon="arcticons:huawei-email" color="#8c8c8c" width="24" />
                    <input type="email" name="" id="" placeholder='Enter Your Email Here !' />
                </div>
                <input type="button" value="Submit" />
            </div>
            <div className="news-line">
                <img src={line} alt="line" />
            </div>
        </div>
    )
}



export default FQA
export { Newsletter }
