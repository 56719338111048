import React from 'react'
import coin1 from "../../assets/images/image 7.png";
import coin2 from "../../assets/images/image 6.png";

const Reward = () => {
    return (
        <div>
            <section className="main-reward">
                <div className='reward'>
                    <div data-aos="fade-right" data-aos-duration="1500" data-aos-once="false" className="left">
                        <h2>Get Rewarded By Next Stay</h2>
                        <p>Earn while you explore – Next Stay rewards your journey with endless possibilities
</p>
                        <div className="button-wrap">
                            <button className="btn-1">Learn more
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M1.89 9.8704C1.89 9.1704 1.89 8.5104 1.89 7.8504C1.89 7.4404 2.02 7.3204 2.42 7.3304C3.56 7.3504 4.64 7.6504 5.72 7.9804C5.79 8.0004 5.87 8.0804 5.89 8.1504C5.97 8.4004 6.03 8.6604 6.11 8.9604C4.98 8.6204 3.89 8.2104 2.69 8.1704C2.69 8.2804 2.67 8.3704 2.67 8.4604C2.67 11.7604 2.67 15.0704 2.67 18.3704C2.67 18.5104 2.63 18.6704 2.87 18.6904C4.08 18.7604 5.22 19.1504 6.38 19.5004C7.53 19.8404 8.7 20.1404 9.86 20.4504C10.05 20.5004 10.25 20.5004 10.48 20.5204V15.9304H11.27V20.5804C11.99 20.4304 12.67 20.3204 13.33 20.1404C14.7 19.7604 16.05 19.3204 17.41 18.9304C17.87 18.8004 18.36 18.7604 18.84 18.7004C19 18.6804 19.09 18.6504 19.09 18.4604C19.09 15.0804 19.09 11.7004 19.09 8.3104C19.09 8.2804 19.08 8.2504 19.05 8.1404C18.48 8.2504 17.9 8.3404 17.33 8.4804C16.77 8.6104 16.21 8.7904 15.62 8.9604C15.7 8.6704 15.77 8.3904 15.85 8.1104C15.87 8.0604 15.94 8.0104 16 8.0004C17.07 7.6604 18.16 7.3604 19.3 7.3404C19.71 7.3404 19.86 7.4704 19.86 7.8804C19.86 8.5304 19.86 9.1804 19.86 9.8804C20.28 9.8804 20.68 9.8804 21.08 9.8804C21.64 9.8804 21.76 10.0004 21.76 10.5504C21.76 13.9304 21.76 17.3104 21.76 20.7004C21.76 21.2004 21.65 21.3104 21.16 21.3104C14.31 21.3104 7.46 21.3104 0.61 21.3104C0.11 21.3104 0 21.2004 0 20.7004C0 17.3004 0 13.9004 0 10.5004C0 9.9904 0.11 9.8804 0.63 9.8804C1.04 9.8804 1.46 9.8804 1.89 9.8804V9.8704ZM20.92 20.4904V10.7304H19.87C19.87 10.8504 19.87 10.9504 19.87 11.0504C19.87 13.6704 19.87 16.2904 19.87 18.9104C19.87 19.3204 19.75 19.4304 19.35 19.4504C19.02 19.4704 18.67 19.4704 18.35 19.5504C17.24 19.8204 16.14 20.1304 15.04 20.4304C15.01 20.4304 14.99 20.4704 14.96 20.5004H20.92V20.4904ZM6.66 20.4904C6.66 20.4904 6.66 20.4604 6.67 20.4404C6.34 20.3304 6 20.2304 5.67 20.1204C4.61 19.7804 3.53 19.4904 2.41 19.4404C1.92 19.4204 1.83 19.3304 1.83 18.8404C1.83 16.2504 1.83 13.6604 1.83 11.0704C1.83 10.9504 1.83 10.8404 1.83 10.7204H0.79V20.4804H6.66V20.4904Z" fill="white" />
                                        <path d="M6.51977 7.28042C6.51977 4.76042 8.27977 2.86042 10.5298 2.66042C12.6998 2.47042 14.7498 4.06042 15.0998 6.24042C15.3498 7.77042 14.8698 9.07042 13.7698 10.1404C13.2798 10.6104 12.8798 11.1404 12.7698 11.8104C12.6898 12.2704 12.7098 12.7504 12.6998 13.2304C12.6998 13.4604 12.6798 13.6604 12.4398 13.7804C12.3698 13.8104 12.3498 13.9404 12.3198 14.0304C12.1698 14.7004 11.7398 15.0604 11.0498 15.1104C10.0198 15.1904 9.45977 14.7404 9.29977 13.9504C9.29977 13.9004 9.27977 13.8304 9.24977 13.8204C8.89977 13.6804 8.94976 13.3804 8.93976 13.0904C8.93976 12.6904 8.93977 12.2804 8.88977 11.8804C8.80977 11.2204 8.47977 10.6804 7.97977 10.2404C7.00977 9.38042 6.53977 8.29042 6.50977 7.28042H6.51977ZM10.8798 11.5504C11.2098 11.5504 11.5298 11.5504 11.8598 11.5504C11.9398 11.5504 12.0698 11.4704 12.0898 11.4004C12.3198 10.6404 12.7798 10.0404 13.3498 9.50042C14.4298 8.45042 14.6998 6.82042 14.0498 5.49042C13.3998 4.15042 11.9198 3.35042 10.4398 3.54042C8.97976 3.73042 8.00977 4.55042 7.53977 5.91042C7.06977 7.29042 7.38977 8.53042 8.43976 9.54042C8.98976 10.0704 9.42977 10.6604 9.67977 11.3904C9.70977 11.4704 9.83977 11.5404 9.92977 11.5504C10.2398 11.5704 10.5598 11.5504 10.8798 11.5504ZM11.8898 12.9804V12.3504H9.80977V12.9804H11.8898ZM11.5098 13.8304H10.1598C10.2698 14.2504 10.4998 14.3704 11.0898 14.3004C11.3598 14.2704 11.5198 14.1304 11.5098 13.8304Z" fill="white" />
                                        <path d="M4.38052 16.4204C4.80052 16.5504 5.31052 16.7104 5.81052 16.8804C6.85052 17.2204 7.88052 17.5704 8.92052 17.9104C9.19052 18.0004 9.31052 18.1904 9.24052 18.4204C9.18052 18.6404 8.94052 18.7804 8.70052 18.7004C7.98052 18.4804 7.26052 18.2404 6.54052 18.0004C5.77052 17.7504 5.01052 17.4904 4.24052 17.2404C3.96052 17.1504 3.81052 16.9704 3.87052 16.7504C3.92052 16.5404 4.06052 16.4104 4.39052 16.4104L4.38052 16.4204Z" fill="white" />
                                        <path d="M4.40971 14.2805C4.75971 14.3905 5.20971 14.5105 5.64971 14.6605C6.73971 15.0205 7.82971 15.4005 8.91971 15.7605C9.16971 15.8405 9.28971 15.9805 9.24971 16.2405C9.20971 16.4705 8.99971 16.6105 8.72971 16.5505C8.27971 16.4405 7.83971 16.3005 7.39971 16.1605C6.33971 15.8105 5.28971 15.4505 4.23971 15.1005C3.99971 15.0205 3.81971 14.8905 3.86971 14.6105C3.90971 14.3805 4.07971 14.2605 4.41971 14.2805H4.40971Z" fill="white" />
                                        <path d="M17.3609 14.2805C17.6409 14.2805 17.8009 14.4005 17.8409 14.6205C17.8909 14.9005 17.7009 15.0205 17.4609 15.1005C16.4609 15.4305 15.4609 15.7705 14.4609 16.1005C14.0409 16.2405 13.6209 16.3705 13.1909 16.5005C13.1609 16.5005 13.1309 16.5205 13.1109 16.5305C12.7709 16.6105 12.5309 16.5305 12.4609 16.2905C12.3909 16.0405 12.5609 15.8305 12.8909 15.7305C14.2409 15.2905 15.5809 14.8405 16.9309 14.3905C17.0909 14.3405 17.2509 14.3105 17.3609 14.2805Z" fill="white" />
                                        <path d="M12.9101 18.7304C12.6901 18.7104 12.5001 18.6404 12.4701 18.4004C12.4301 18.1604 12.5601 18.0004 12.7801 17.9204C13.0401 17.8304 13.3101 17.7804 13.5701 17.6904C14.7201 17.3004 15.8601 16.9104 17.0101 16.5204C17.1001 16.4904 17.2001 16.4604 17.3001 16.4404C17.5601 16.3804 17.7801 16.5004 17.8501 16.7204C17.9101 16.9204 17.7601 17.1604 17.5201 17.2404C17.1001 17.3704 16.6701 17.4904 16.2501 17.6304C15.2001 17.9804 14.1601 18.3304 13.1101 18.6804C13.0501 18.7004 12.9801 18.7204 12.9201 18.7304H12.9101Z" fill="white" />
                                        <path d="M13.2106 14.3006C13.3006 14.0306 13.3906 13.7606 13.4906 13.4906C13.5106 13.4306 13.5906 13.3706 13.6506 13.3506C14.7906 12.9606 15.9406 12.5706 17.0906 12.1906C17.2506 12.1406 17.4506 12.1206 17.6006 12.1706C17.7106 12.2106 17.8206 12.4006 17.8406 12.5306C17.8506 12.6306 17.7406 12.8106 17.6406 12.8406C16.1706 13.3406 14.6906 13.8206 13.2106 14.3006C13.1906 14.3006 13.1706 14.3006 13.2106 14.3006Z" fill="white" />
                                        <path d="M8.54902 14.3405C7.98902 14.1605 7.48902 13.9905 6.98902 13.8305C6.05902 13.5205 5.11902 13.2105 4.18902 12.9005C3.95902 12.8205 3.79902 12.7005 3.84902 12.4305C3.89902 12.1905 4.12902 12.0505 4.39902 12.1405C5.34902 12.4505 6.29902 12.7705 7.25902 13.0805C7.36902 13.1205 7.47902 13.1705 7.58902 13.2005C7.95902 13.2805 8.26902 13.3905 8.28902 13.8605C8.28902 14.0005 8.42902 14.1305 8.53902 14.3405H8.54902Z" fill="white" />
                                        <path d="M13.5195 12.0304C13.6695 11.4604 13.8995 11.0304 14.4995 10.8604C15.3795 10.6104 16.2395 10.2804 17.0995 9.99037C17.1895 9.96037 17.2895 9.93037 17.3895 9.92037C17.6195 9.90037 17.7695 10.0204 17.8295 10.2404C17.8895 10.4604 17.8095 10.6404 17.5895 10.7104C16.2795 11.1504 14.9795 11.5804 13.6695 12.0204C13.6395 12.0304 13.5895 12.0204 13.5195 12.0304Z" fill="white" />
                                        <path d="M8.18974 12.0806C7.71974 11.9206 7.29974 11.7706 6.87974 11.6306C5.97974 11.3306 5.08974 11.0306 4.18974 10.7406C3.95974 10.6706 3.82974 10.5406 3.85974 10.2906C3.88974 10.0606 4.12974 9.93056 4.37974 9.97056C4.41974 9.97056 4.45974 9.99056 4.48974 10.0006C5.30974 10.2706 6.13974 10.5406 6.95974 10.8106C7.03974 10.8406 7.11974 10.8806 7.20974 10.9006C7.82974 11.0106 8.04974 11.4506 8.17974 12.0806H8.18974Z" fill="white" />
                                        <path d="M8.83005 1.8005C8.72005 1.9205 8.62005 2.1105 8.50005 2.1205C8.35005 2.1405 8.11005 2.0805 8.02005 1.9605C7.84005 1.7305 7.73005 1.4505 7.62005 1.1705C7.54005 0.970497 7.58005 0.770497 7.80005 0.670497C8.02005 0.570497 8.23005 0.600497 8.36005 0.840497C8.51005 1.1405 8.66005 1.4405 8.83005 1.8005Z" fill="white" />
                                        <path d="M5.16083 6.04044C4.93083 5.99044 4.62083 5.93044 4.32083 5.83044C4.12083 5.76044 4.04083 5.57044 4.11083 5.37044C4.17083 5.16044 4.33083 5.03045 4.55083 5.07045C4.83083 5.12045 5.11083 5.19045 5.38083 5.26045C5.61083 5.32045 5.73083 5.49044 5.68083 5.73044C5.63083 5.95044 5.46083 6.05044 5.16083 6.04044Z" fill="white" />
                                        <path d="M14.1902 1.07039C13.9702 1.45039 13.8102 1.80039 13.5802 2.09039C13.5202 2.17039 13.2202 2.17039 13.1102 2.09039C13.0102 2.02039 12.9402 1.77039 12.9802 1.64039C13.0602 1.37039 13.1902 1.10039 13.3602 0.880395C13.4502 0.760395 13.6802 0.670395 13.8302 0.700395C13.9602 0.720395 14.0602 0.920395 14.1902 1.07039Z" fill="white" />
                                        <path d="M11.2603 0.810444C11.2603 0.950444 11.2703 1.09044 11.2603 1.22044C11.2303 1.45044 11.0903 1.61044 10.8503 1.60044C10.6203 1.60044 10.4803 1.45044 10.4603 1.23044C10.4403 0.950444 10.4403 0.660444 10.4603 0.380444C10.4803 0.150444 10.6303 -0.00955625 10.8703 0.000443754C11.1103 0.000443754 11.2403 0.170444 11.2603 0.400444C11.2703 0.540444 11.2603 0.680444 11.2603 0.810444Z" fill="white" />
                                        <path d="M16.5708 6.04043C16.2408 6.04043 16.0808 5.94043 16.0408 5.72043C16.0008 5.49043 16.1208 5.33043 16.3308 5.27043C16.6108 5.18043 16.9008 5.12043 17.1908 5.07043C17.4208 5.03043 17.5708 5.16043 17.6208 5.38043C17.6808 5.60043 17.5808 5.77043 17.3808 5.83043C17.0808 5.93043 16.7708 5.99043 16.5708 6.04043Z" fill="white" />
                                        <path d="M6.44004 3.8204C6.31004 3.7604 6.22004 3.7404 6.15004 3.6904C5.92004 3.5204 5.70004 3.3404 5.48004 3.1504C5.30004 3.0004 5.29004 2.8104 5.43004 2.6304C5.57004 2.4404 5.76004 2.4104 5.95004 2.5404C6.20004 2.7204 6.45004 2.9004 6.66004 3.1204C6.74004 3.2004 6.78004 3.4004 6.74004 3.5104C6.70004 3.6404 6.54004 3.7204 6.44004 3.8304V3.8204Z" fill="white" />
                                        <path d="M15.2498 3.78044C15.1898 3.73044 15.0298 3.65044 14.9698 3.53044C14.9198 3.42044 14.9498 3.22044 15.0298 3.13044C15.2598 2.90044 15.5098 2.69044 15.7798 2.51044C15.8698 2.45044 16.0798 2.48044 16.1798 2.55044C16.2798 2.62044 16.3498 2.79044 16.3498 2.92044C16.3498 3.12044 15.5398 3.76044 15.2498 3.77044V3.78044Z" fill="white" />
                                        <path d="M13.3996 6.87044C13.3996 7.18044 13.2696 7.37044 13.0396 7.39044C12.8096 7.41044 12.6196 7.24044 12.6096 6.93044C12.5896 6.05044 11.8296 5.26044 10.9196 5.23044C10.6096 5.23044 10.4396 5.03044 10.4496 4.81044C10.4596 4.57044 10.6696 4.43044 10.9896 4.44044C12.2396 4.47044 13.3796 5.62044 13.3996 6.87044Z" fill="white" />
                                    </svg>
                                </span>
                            </button>
                            {/* <button className="btn-2">Download App
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="26" viewBox="0 0 21 26" fill="none">
                                        <path d="M19.0338 15.54C18.8338 11.93 17.1638 9.21 13.8338 7.55C13.6438 7.45 13.4438 7.27 13.3538 7.08C13.2538 6.87 13.3338 6.63 13.5238 6.47C13.7338 6.29 13.9738 6.28 14.2138 6.39C15.0838 6.77 15.8938 7.27 16.6138 7.89C19.2138 10.15 20.4138 13.01 20.1838 16.44C19.8738 20.97 16.3638 24.8 11.8938 25.56C6.48378 26.49 1.40378 23.07 0.223783 17.71C-0.776217 13.13 1.65378 8.32 5.94378 6.41C6.35378 6.23 6.69378 6.32 6.85378 6.66C7.02378 7.01 6.86378 7.32999 6.44378 7.52999C3.74378 8.81999 2.04378 10.92 1.40378 13.84C0.463783 18.14 3.06378 22.65 7.24378 24.02C12.1738 25.64 17.2738 22.92 18.6738 17.93C18.7638 17.62 18.8338 17.3 18.8838 16.98C18.9438 16.52 18.9738 16.06 19.0138 15.53L19.0338 15.54Z" fill="white" />
                                        <path d="M9.51454 17.44C9.51454 17.26 9.51454 17.15 9.51454 17.04C9.51454 13.42 9.51454 9.79997 9.51454 6.18997C9.51454 6.08997 9.51454 5.98997 9.51454 5.88997C9.52454 5.48997 9.76454 5.21997 10.1145 5.21997C10.4645 5.21997 10.7145 5.48997 10.7345 5.90997C10.7345 6.00997 10.7345 6.10997 10.7345 6.20997C10.7345 9.80997 10.7345 13.41 10.7345 17V17.43C10.8745 17.31 10.9845 17.23 11.0745 17.15C11.8845 16.47 12.6845 15.78 13.4945 15.1C13.7245 14.91 13.9745 14.87 14.2345 15.02C14.4845 15.17 14.5845 15.41 14.5045 15.68C14.4545 15.85 14.3145 16 14.1845 16.12C13.0945 17.06 11.9745 17.98 10.8945 18.93C10.1545 19.58 10.0145 19.49 9.36454 18.92C8.28454 17.98 7.18454 17.06 6.09454 16.13C5.95454 16.01 5.81454 15.86 5.76454 15.7C5.67454 15.44 5.76454 15.19 6.01454 15.03C6.27454 14.87 6.52454 14.9 6.75454 15.09C7.40454 15.64 8.05454 16.19 8.70454 16.74C8.96454 16.96 9.21454 17.17 9.53454 17.44H9.51454Z" fill="white" />
                                        <path d="M9.51367 3.34999C9.51367 3.00999 9.78367 2.71999 10.1237 2.72999C10.4537 2.72999 10.7137 2.99999 10.7237 3.33999C10.7237 3.68999 10.4537 3.96999 10.1137 3.95999C9.78367 3.95999 9.52367 3.68999 9.51367 3.34999Z" fill="white" />
                                        <path d="M9.51367 0.610001C9.51367 0.280001 9.77367 0.01 10.1037 0C10.4437 0 10.7237 0.270001 10.7237 0.610001C10.7237 0.940001 10.4637 1.22 10.1337 1.22C9.80367 1.22 9.51367 0.950002 9.51367 0.600002V0.610001Z" fill="white" />
                                    </svg>
                                </span>
                            </button> */}
                        </div>
                    </div>
                    <div className="right">
                        <div className="coin1">
                            <img src={coin1} alt="coin" />
                        </div>
                        <video autoPlay loop muted playsInline>
                            <source
                                src="\video/blockchain-money-3d-animation.mp4"
                                type="video/mp4"
                            />
                        </video>
                        <div className="coin2">
                            <img src={coin2} alt="coin" />
                        </div>
                    </div>
                </div>
                <div className="vector">
                    <img src="\images/Vector.png" alt="" />
                </div>
            </section>
            <section className='virtual-tour'>
                <div className="left">
                    <video autoPlay loop muted playsInline>
                        <source
                            src="\video/white-virtual-reality-headset-rotating-on-transpar.mp4"
                            type="video/mp4"
                        />
                    </video>
                </div>
                <div data-aos="fade-left" data-aos-duration="1000" data-aos-once="false" className="right">
                    <h2>Have a Virtual Tour !</h2>
                    <p>Step into the virtual world with Next Stay's immersive property meta tours. Explore homes, apartments, and unique spaces as if you're physically there. Wander through rooms, admire the views, and get a real sense of your future property. Our cutting-edge technology makes virtual property tours feel like a captivating reality, giving you the ultimate preview before you make your real estate choices.
</p>
                    <div className="button-wrap">
                        <button className="btn-1">Learn more
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M1.89 9.8704C1.89 9.1704 1.89 8.5104 1.89 7.8504C1.89 7.4404 2.02 7.3204 2.42 7.3304C3.56 7.3504 4.64 7.6504 5.72 7.9804C5.79 8.0004 5.87 8.0804 5.89 8.1504C5.97 8.4004 6.03 8.6604 6.11 8.9604C4.98 8.6204 3.89 8.2104 2.69 8.1704C2.69 8.2804 2.67 8.3704 2.67 8.4604C2.67 11.7604 2.67 15.0704 2.67 18.3704C2.67 18.5104 2.63 18.6704 2.87 18.6904C4.08 18.7604 5.22 19.1504 6.38 19.5004C7.53 19.8404 8.7 20.1404 9.86 20.4504C10.05 20.5004 10.25 20.5004 10.48 20.5204V15.9304H11.27V20.5804C11.99 20.4304 12.67 20.3204 13.33 20.1404C14.7 19.7604 16.05 19.3204 17.41 18.9304C17.87 18.8004 18.36 18.7604 18.84 18.7004C19 18.6804 19.09 18.6504 19.09 18.4604C19.09 15.0804 19.09 11.7004 19.09 8.3104C19.09 8.2804 19.08 8.2504 19.05 8.1404C18.48 8.2504 17.9 8.3404 17.33 8.4804C16.77 8.6104 16.21 8.7904 15.62 8.9604C15.7 8.6704 15.77 8.3904 15.85 8.1104C15.87 8.0604 15.94 8.0104 16 8.0004C17.07 7.6604 18.16 7.3604 19.3 7.3404C19.71 7.3404 19.86 7.4704 19.86 7.8804C19.86 8.5304 19.86 9.1804 19.86 9.8804C20.28 9.8804 20.68 9.8804 21.08 9.8804C21.64 9.8804 21.76 10.0004 21.76 10.5504C21.76 13.9304 21.76 17.3104 21.76 20.7004C21.76 21.2004 21.65 21.3104 21.16 21.3104C14.31 21.3104 7.46 21.3104 0.61 21.3104C0.11 21.3104 0 21.2004 0 20.7004C0 17.3004 0 13.9004 0 10.5004C0 9.9904 0.11 9.8804 0.63 9.8804C1.04 9.8804 1.46 9.8804 1.89 9.8804V9.8704ZM20.92 20.4904V10.7304H19.87C19.87 10.8504 19.87 10.9504 19.87 11.0504C19.87 13.6704 19.87 16.2904 19.87 18.9104C19.87 19.3204 19.75 19.4304 19.35 19.4504C19.02 19.4704 18.67 19.4704 18.35 19.5504C17.24 19.8204 16.14 20.1304 15.04 20.4304C15.01 20.4304 14.99 20.4704 14.96 20.5004H20.92V20.4904ZM6.66 20.4904C6.66 20.4904 6.66 20.4604 6.67 20.4404C6.34 20.3304 6 20.2304 5.67 20.1204C4.61 19.7804 3.53 19.4904 2.41 19.4404C1.92 19.4204 1.83 19.3304 1.83 18.8404C1.83 16.2504 1.83 13.6604 1.83 11.0704C1.83 10.9504 1.83 10.8404 1.83 10.7204H0.79V20.4804H6.66V20.4904Z" fill="white" />
                                    <path d="M6.51977 7.28042C6.51977 4.76042 8.27977 2.86042 10.5298 2.66042C12.6998 2.47042 14.7498 4.06042 15.0998 6.24042C15.3498 7.77042 14.8698 9.07042 13.7698 10.1404C13.2798 10.6104 12.8798 11.1404 12.7698 11.8104C12.6898 12.2704 12.7098 12.7504 12.6998 13.2304C12.6998 13.4604 12.6798 13.6604 12.4398 13.7804C12.3698 13.8104 12.3498 13.9404 12.3198 14.0304C12.1698 14.7004 11.7398 15.0604 11.0498 15.1104C10.0198 15.1904 9.45977 14.7404 9.29977 13.9504C9.29977 13.9004 9.27977 13.8304 9.24977 13.8204C8.89977 13.6804 8.94976 13.3804 8.93976 13.0904C8.93976 12.6904 8.93977 12.2804 8.88977 11.8804C8.80977 11.2204 8.47977 10.6804 7.97977 10.2404C7.00977 9.38042 6.53977 8.29042 6.50977 7.28042H6.51977ZM10.8798 11.5504C11.2098 11.5504 11.5298 11.5504 11.8598 11.5504C11.9398 11.5504 12.0698 11.4704 12.0898 11.4004C12.3198 10.6404 12.7798 10.0404 13.3498 9.50042C14.4298 8.45042 14.6998 6.82042 14.0498 5.49042C13.3998 4.15042 11.9198 3.35042 10.4398 3.54042C8.97976 3.73042 8.00977 4.55042 7.53977 5.91042C7.06977 7.29042 7.38977 8.53042 8.43976 9.54042C8.98976 10.0704 9.42977 10.6604 9.67977 11.3904C9.70977 11.4704 9.83977 11.5404 9.92977 11.5504C10.2398 11.5704 10.5598 11.5504 10.8798 11.5504ZM11.8898 12.9804V12.3504H9.80977V12.9804H11.8898ZM11.5098 13.8304H10.1598C10.2698 14.2504 10.4998 14.3704 11.0898 14.3004C11.3598 14.2704 11.5198 14.1304 11.5098 13.8304Z" fill="white" />
                                    <path d="M4.38052 16.4204C4.80052 16.5504 5.31052 16.7104 5.81052 16.8804C6.85052 17.2204 7.88052 17.5704 8.92052 17.9104C9.19052 18.0004 9.31052 18.1904 9.24052 18.4204C9.18052 18.6404 8.94052 18.7804 8.70052 18.7004C7.98052 18.4804 7.26052 18.2404 6.54052 18.0004C5.77052 17.7504 5.01052 17.4904 4.24052 17.2404C3.96052 17.1504 3.81052 16.9704 3.87052 16.7504C3.92052 16.5404 4.06052 16.4104 4.39052 16.4104L4.38052 16.4204Z" fill="white" />
                                    <path d="M4.40971 14.2805C4.75971 14.3905 5.20971 14.5105 5.64971 14.6605C6.73971 15.0205 7.82971 15.4005 8.91971 15.7605C9.16971 15.8405 9.28971 15.9805 9.24971 16.2405C9.20971 16.4705 8.99971 16.6105 8.72971 16.5505C8.27971 16.4405 7.83971 16.3005 7.39971 16.1605C6.33971 15.8105 5.28971 15.4505 4.23971 15.1005C3.99971 15.0205 3.81971 14.8905 3.86971 14.6105C3.90971 14.3805 4.07971 14.2605 4.41971 14.2805H4.40971Z" fill="white" />
                                    <path d="M17.3609 14.2805C17.6409 14.2805 17.8009 14.4005 17.8409 14.6205C17.8909 14.9005 17.7009 15.0205 17.4609 15.1005C16.4609 15.4305 15.4609 15.7705 14.4609 16.1005C14.0409 16.2405 13.6209 16.3705 13.1909 16.5005C13.1609 16.5005 13.1309 16.5205 13.1109 16.5305C12.7709 16.6105 12.5309 16.5305 12.4609 16.2905C12.3909 16.0405 12.5609 15.8305 12.8909 15.7305C14.2409 15.2905 15.5809 14.8405 16.9309 14.3905C17.0909 14.3405 17.2509 14.3105 17.3609 14.2805Z" fill="white" />
                                    <path d="M12.9101 18.7304C12.6901 18.7104 12.5001 18.6404 12.4701 18.4004C12.4301 18.1604 12.5601 18.0004 12.7801 17.9204C13.0401 17.8304 13.3101 17.7804 13.5701 17.6904C14.7201 17.3004 15.8601 16.9104 17.0101 16.5204C17.1001 16.4904 17.2001 16.4604 17.3001 16.4404C17.5601 16.3804 17.7801 16.5004 17.8501 16.7204C17.9101 16.9204 17.7601 17.1604 17.5201 17.2404C17.1001 17.3704 16.6701 17.4904 16.2501 17.6304C15.2001 17.9804 14.1601 18.3304 13.1101 18.6804C13.0501 18.7004 12.9801 18.7204 12.9201 18.7304H12.9101Z" fill="white" />
                                    <path d="M13.2106 14.3006C13.3006 14.0306 13.3906 13.7606 13.4906 13.4906C13.5106 13.4306 13.5906 13.3706 13.6506 13.3506C14.7906 12.9606 15.9406 12.5706 17.0906 12.1906C17.2506 12.1406 17.4506 12.1206 17.6006 12.1706C17.7106 12.2106 17.8206 12.4006 17.8406 12.5306C17.8506 12.6306 17.7406 12.8106 17.6406 12.8406C16.1706 13.3406 14.6906 13.8206 13.2106 14.3006C13.1906 14.3006 13.1706 14.3006 13.2106 14.3006Z" fill="white" />
                                    <path d="M8.54902 14.3405C7.98902 14.1605 7.48902 13.9905 6.98902 13.8305C6.05902 13.5205 5.11902 13.2105 4.18902 12.9005C3.95902 12.8205 3.79902 12.7005 3.84902 12.4305C3.89902 12.1905 4.12902 12.0505 4.39902 12.1405C5.34902 12.4505 6.29902 12.7705 7.25902 13.0805C7.36902 13.1205 7.47902 13.1705 7.58902 13.2005C7.95902 13.2805 8.26902 13.3905 8.28902 13.8605C8.28902 14.0005 8.42902 14.1305 8.53902 14.3405H8.54902Z" fill="white" />
                                    <path d="M13.5195 12.0304C13.6695 11.4604 13.8995 11.0304 14.4995 10.8604C15.3795 10.6104 16.2395 10.2804 17.0995 9.99037C17.1895 9.96037 17.2895 9.93037 17.3895 9.92037C17.6195 9.90037 17.7695 10.0204 17.8295 10.2404C17.8895 10.4604 17.8095 10.6404 17.5895 10.7104C16.2795 11.1504 14.9795 11.5804 13.6695 12.0204C13.6395 12.0304 13.5895 12.0204 13.5195 12.0304Z" fill="white" />
                                    <path d="M8.18974 12.0806C7.71974 11.9206 7.29974 11.7706 6.87974 11.6306C5.97974 11.3306 5.08974 11.0306 4.18974 10.7406C3.95974 10.6706 3.82974 10.5406 3.85974 10.2906C3.88974 10.0606 4.12974 9.93056 4.37974 9.97056C4.41974 9.97056 4.45974 9.99056 4.48974 10.0006C5.30974 10.2706 6.13974 10.5406 6.95974 10.8106C7.03974 10.8406 7.11974 10.8806 7.20974 10.9006C7.82974 11.0106 8.04974 11.4506 8.17974 12.0806H8.18974Z" fill="white" />
                                    <path d="M8.83005 1.8005C8.72005 1.9205 8.62005 2.1105 8.50005 2.1205C8.35005 2.1405 8.11005 2.0805 8.02005 1.9605C7.84005 1.7305 7.73005 1.4505 7.62005 1.1705C7.54005 0.970497 7.58005 0.770497 7.80005 0.670497C8.02005 0.570497 8.23005 0.600497 8.36005 0.840497C8.51005 1.1405 8.66005 1.4405 8.83005 1.8005Z" fill="white" />
                                    <path d="M5.16083 6.04044C4.93083 5.99044 4.62083 5.93044 4.32083 5.83044C4.12083 5.76044 4.04083 5.57044 4.11083 5.37044C4.17083 5.16044 4.33083 5.03045 4.55083 5.07045C4.83083 5.12045 5.11083 5.19045 5.38083 5.26045C5.61083 5.32045 5.73083 5.49044 5.68083 5.73044C5.63083 5.95044 5.46083 6.05044 5.16083 6.04044Z" fill="white" />
                                    <path d="M14.1902 1.07039C13.9702 1.45039 13.8102 1.80039 13.5802 2.09039C13.5202 2.17039 13.2202 2.17039 13.1102 2.09039C13.0102 2.02039 12.9402 1.77039 12.9802 1.64039C13.0602 1.37039 13.1902 1.10039 13.3602 0.880395C13.4502 0.760395 13.6802 0.670395 13.8302 0.700395C13.9602 0.720395 14.0602 0.920395 14.1902 1.07039Z" fill="white" />
                                    <path d="M11.2603 0.810444C11.2603 0.950444 11.2703 1.09044 11.2603 1.22044C11.2303 1.45044 11.0903 1.61044 10.8503 1.60044C10.6203 1.60044 10.4803 1.45044 10.4603 1.23044C10.4403 0.950444 10.4403 0.660444 10.4603 0.380444C10.4803 0.150444 10.6303 -0.00955625 10.8703 0.000443754C11.1103 0.000443754 11.2403 0.170444 11.2603 0.400444C11.2703 0.540444 11.2603 0.680444 11.2603 0.810444Z" fill="white" />
                                    <path d="M16.5708 6.04043C16.2408 6.04043 16.0808 5.94043 16.0408 5.72043C16.0008 5.49043 16.1208 5.33043 16.3308 5.27043C16.6108 5.18043 16.9008 5.12043 17.1908 5.07043C17.4208 5.03043 17.5708 5.16043 17.6208 5.38043C17.6808 5.60043 17.5808 5.77043 17.3808 5.83043C17.0808 5.93043 16.7708 5.99043 16.5708 6.04043Z" fill="white" />
                                    <path d="M6.44004 3.8204C6.31004 3.7604 6.22004 3.7404 6.15004 3.6904C5.92004 3.5204 5.70004 3.3404 5.48004 3.1504C5.30004 3.0004 5.29004 2.8104 5.43004 2.6304C5.57004 2.4404 5.76004 2.4104 5.95004 2.5404C6.20004 2.7204 6.45004 2.9004 6.66004 3.1204C6.74004 3.2004 6.78004 3.4004 6.74004 3.5104C6.70004 3.6404 6.54004 3.7204 6.44004 3.8304V3.8204Z" fill="white" />
                                    <path d="M15.2498 3.78044C15.1898 3.73044 15.0298 3.65044 14.9698 3.53044C14.9198 3.42044 14.9498 3.22044 15.0298 3.13044C15.2598 2.90044 15.5098 2.69044 15.7798 2.51044C15.8698 2.45044 16.0798 2.48044 16.1798 2.55044C16.2798 2.62044 16.3498 2.79044 16.3498 2.92044C16.3498 3.12044 15.5398 3.76044 15.2498 3.77044V3.78044Z" fill="white" />
                                    <path d="M13.3996 6.87044C13.3996 7.18044 13.2696 7.37044 13.0396 7.39044C12.8096 7.41044 12.6196 7.24044 12.6096 6.93044C12.5896 6.05044 11.8296 5.26044 10.9196 5.23044C10.6096 5.23044 10.4396 5.03044 10.4496 4.81044C10.4596 4.57044 10.6696 4.43044 10.9896 4.44044C12.2396 4.47044 13.3796 5.62044 13.3996 6.87044Z" fill="white" />
                                </svg>
                            </span>
                        </button>
                        {/* <button className="btn-2">Download App
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="26" viewBox="0 0 21 26" fill="none">
                                    <path d="M19.0338 15.54C18.8338 11.93 17.1638 9.21 13.8338 7.55C13.6438 7.45 13.4438 7.27 13.3538 7.08C13.2538 6.87 13.3338 6.63 13.5238 6.47C13.7338 6.29 13.9738 6.28 14.2138 6.39C15.0838 6.77 15.8938 7.27 16.6138 7.89C19.2138 10.15 20.4138 13.01 20.1838 16.44C19.8738 20.97 16.3638 24.8 11.8938 25.56C6.48378 26.49 1.40378 23.07 0.223783 17.71C-0.776217 13.13 1.65378 8.32 5.94378 6.41C6.35378 6.23 6.69378 6.32 6.85378 6.66C7.02378 7.01 6.86378 7.32999 6.44378 7.52999C3.74378 8.81999 2.04378 10.92 1.40378 13.84C0.463783 18.14 3.06378 22.65 7.24378 24.02C12.1738 25.64 17.2738 22.92 18.6738 17.93C18.7638 17.62 18.8338 17.3 18.8838 16.98C18.9438 16.52 18.9738 16.06 19.0138 15.53L19.0338 15.54Z" fill="white" />
                                    <path d="M9.51454 17.44C9.51454 17.26 9.51454 17.15 9.51454 17.04C9.51454 13.42 9.51454 9.79997 9.51454 6.18997C9.51454 6.08997 9.51454 5.98997 9.51454 5.88997C9.52454 5.48997 9.76454 5.21997 10.1145 5.21997C10.4645 5.21997 10.7145 5.48997 10.7345 5.90997C10.7345 6.00997 10.7345 6.10997 10.7345 6.20997C10.7345 9.80997 10.7345 13.41 10.7345 17V17.43C10.8745 17.31 10.9845 17.23 11.0745 17.15C11.8845 16.47 12.6845 15.78 13.4945 15.1C13.7245 14.91 13.9745 14.87 14.2345 15.02C14.4845 15.17 14.5845 15.41 14.5045 15.68C14.4545 15.85 14.3145 16 14.1845 16.12C13.0945 17.06 11.9745 17.98 10.8945 18.93C10.1545 19.58 10.0145 19.49 9.36454 18.92C8.28454 17.98 7.18454 17.06 6.09454 16.13C5.95454 16.01 5.81454 15.86 5.76454 15.7C5.67454 15.44 5.76454 15.19 6.01454 15.03C6.27454 14.87 6.52454 14.9 6.75454 15.09C7.40454 15.64 8.05454 16.19 8.70454 16.74C8.96454 16.96 9.21454 17.17 9.53454 17.44H9.51454Z" fill="white" />
                                    <path d="M9.51367 3.34999C9.51367 3.00999 9.78367 2.71999 10.1237 2.72999C10.4537 2.72999 10.7137 2.99999 10.7237 3.33999C10.7237 3.68999 10.4537 3.96999 10.1137 3.95999C9.78367 3.95999 9.52367 3.68999 9.51367 3.34999Z" fill="white" />
                                    <path d="M9.51367 0.610001C9.51367 0.280001 9.77367 0.01 10.1037 0C10.4437 0 10.7237 0.270001 10.7237 0.610001C10.7237 0.940001 10.4637 1.22 10.1337 1.22C9.80367 1.22 9.51367 0.950002 9.51367 0.600002V0.610001Z" fill="white" />
                                </svg>
                            </span>
                        </button> */}
                    </div>
                </div>
            </section>

            {/* <div className="main-downlod-wrap">
                <img className="img1" src="\images/gpt9-left.png" alt="" />
                <img className="img2" src="\images/gpt9-right.png" alt="" />
                <section className='download-app'>
                    <div className="left">
                        <h2>Download the app now !!</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        <div className="button-wrap">
                            <button className="btn-1">Download App for IOS</button>
                            <button className="btn-2">Download App for Andriod</button>
                        </div>
                    </div>
                    <div className="right">
                        <img src="\images/2441.png" alt="" />
                    </div>
                </section>
            </div> */}
        </div>
    )
}

export default Reward
