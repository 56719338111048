import React from "react";
import "./navbar.scss";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import nextstay from "../../assets/icons/nextstaylogo1.png"
function Navbar() {
  return (
    <section className="Navbar-sec">
      <nav class="navbar navbar-expand-lg ">
        <div class="container-fluid">
          <div className="nextlogo">

          
          <Link class="navbar-brand" to="/">
            <img
              className="logo img-fluid  custom-logo"
              src={nextstay}
              alt="..."
            />
          </Link>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
            
          >
            <Icon icon="mingcute:menu-fill" color="white" border="none" outline="none" style={{border:"0px"}}  />{" "}
          </button>
          <div class="collapse navbar-collapse" id="navbarScroll">
            <ul class="navbar-nav me-auto ms-auto  navbar-nav-scroll">
              <li class="nav-item">
                <Link class="nav-link " aria-current="page" to="/">
                  How To Use
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/">
                  White paper
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/">
                  Metaverse
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/">
                  Marketplace
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/">
                  Help
                </Link>
              </li>
            </ul>
            {/* <form class="d-flex" role="search">
              <button className="wallet-btn" type="submit">
                Download App
              </button>
            </form> */}
          </div>
        </div>
      </nav>
    </section>
  );
}

export default Navbar;
